/* eslint-disable @typescript-eslint/no-explicit-any */
import type { EntryPoint } from 'react-relay';
import { JSResourceForUserVisible } from '@atlassian/react-async';
import forAllEntryPoints from '../for-all-entry-points/forAllEntryPoints.tsx';
import ReactFragment from './ReactFragment.tsx';

const DummyAsyncModule = JSResourceForUserVisible(
	() => import(/* webpackChunkName: "dummy-async-module" */ './ReactFragment.tsx'), // using a real async import to make the js-resource transformers/babel-plugins happy
);
DummyAsyncModule.getModuleIfRequired = () => ReactFragment; // prevent attempts to fetch the module async at all
/**
 * Run a given loadEntryPoint function without loading the entrypoint modules, then load them right afterwards.
 *
 * This is to prevent triggering the loading of entrypoints modules inline with the data fetching.
 * When JS modules are inline-required (such as in SSR when async modules are converted to inline-requires)
 * this leads to inline-evaluating all dependencies for large component modules before kicking off the data for them.
 * This causes a measurable performance hit (~20-80ms in many cases) before we even kick off critical data fetches.
 *
 * Deferring the module loading until after the data fetching allows the module execution to occur in the less critical
 * idle time while waiting for data fetches to return.
 */
function loadEntryPointDataThenModules<TReturn, TParams>(
	entryPoint: EntryPoint<any, any>,
	entryPointParams: TParams,
	loadEntryPointCallback: () => TReturn,
) {
	const originalModulesByEntrypointRef = new Map<typeof entryPoint, (typeof entryPoint)['root']>();
	forAllEntryPoints(
		entryPoint,
		entryPointParams,
		(loopingEntryPoint: {
			-readonly [K in keyof typeof entryPoint]: (typeof entryPoint)[K];
		}) => {
			originalModulesByEntrypointRef.set(loopingEntryPoint, loopingEntryPoint.root);
			// eslint-disable-next-line no-param-reassign
			loopingEntryPoint.root = DummyAsyncModule;
		},
	);
	try {
		return loadEntryPointCallback();
	} finally {
		forAllEntryPoints(
			entryPoint,
			entryPointParams,
			(loopingEntryPoint: {
				-readonly [K in keyof typeof entryPoint]: (typeof entryPoint)[K];
			}) => {
				const originalModule = originalModulesByEntrypointRef.get(loopingEntryPoint);
				if (!originalModule) {
					throw new Error('Original module not found for entrypoint');
				}
				// eslint-disable-next-line no-param-reassign
				loopingEntryPoint.root = originalModule;
			},
		);
		// Kicks off the loading of the original modules as they would have, but after the data fetches have been started
		// Without this, entrypoints may have trouble rendering
		// Wrap it in a promise to prevent it from affecting timers that start synchronously after this function
		Promise.resolve().then(() =>
			originalModulesByEntrypointRef.forEach((entryPointModule) => {
				const jsModule = entryPointModule.getModuleIfRequired();
				if (jsModule != null) {
					return;
				}
				entryPointModule.load();
			}),
		);
	}
}

export default loadEntryPointDataThenModules;
