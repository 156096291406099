import React, { useMemo, useCallback } from 'react';
import AppIcon from '@atlaskit/icon/core/app';
import SettingsIcon from '@atlaskit/icon/core/settings';
import { LinkItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getATLContextDomain } from '@atlassian/atl-context';
import {
	FIXED_ITEM_ACTION,
	FIXED_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	LEVEL_ONE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { SECTION_ITEM_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import {
	type OnCloseProps,
	L1_MENU_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useStopPropagation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-stop-propagation/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import type { MoreNavMenuButtonMenuItemsProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/submenu/index.tsx';
import { getAppRequestsLink } from '@atlassian/jira-platform-marketplace-url-resolver/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';

export function SectionActions({ onClose }: OnCloseProps) {
	const tenantContext = useTenantContext();
	const { isAdmin, isSiteAdmin } = tenantContext;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const showManageApps = isAdmin || isSiteAdmin;
	const showAppAuditLogs = fg('upm_frontend_deprecation') && (isAdmin || isSiteAdmin);

	const showViewAppRequests = expVal('enduser-emcee-discover', 'value', true) && isSiteAdmin;

	const fireOpenEvent = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_APPS],
			attributes: {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM_BUTTON,
				itemId: 'meatballDropdown',
			},
		});
	}, [createAnalyticsEvent]);

	return (
		<MoreNavMenuButton
			MenuItems={(props) => (
				<MoreMenuItems
					showManageApps={showManageApps}
					showViewAppRequests={showViewAppRequests}
					showAppAuditLogs={showAppAuditLogs}
					{...props}
				/>
			)}
			onOpen={fireOpenEvent}
			onClose={onClose}
		/>
	);
}

type MoreMenuItemsProps = {
	showManageApps: boolean;
	showViewAppRequests: boolean;
	showAppAuditLogs?: boolean;
} & MoreNavMenuButtonMenuItemsProps;

function MoreMenuItems({
	onClose,
	showManageApps,
	showViewAppRequests,
	showAppAuditLogs,
}: MoreMenuItemsProps) {
	const tenantContext = useTenantContext();
	const { cloudId } = tenantContext;

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const adminHubAppRequestsUrl = `https://${getATLContextDomain('admin')}/s/${cloudId}/user-connected-apps/tab/requested`;

	const viewAppRequestsLink = useMemo(
		() =>
			fg('should_redirect_to_admin-hub_for_app_requests')
				? adminHubAppRequestsUrl
				: getAppRequestsLink(),
		[adminHubAppRequestsUrl],
	);
	const onCloseWithStopPropagation = useStopPropagation(onClose);
	const showTopSection = showManageApps || showViewAppRequests;

	const fireClickEvent = useCallback(
		(itemId: 'manageApps' | 'viewAppRequests') => {
			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'clicked',
				actionSubject: 'dropdownItem',
				actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_APPS],
				attributes: {
					level: LEVEL_TWO,
					componentType: FIXED_ITEM_ACTION,
					itemId,
					parentItemId: SECTION_ITEM_APPS,
				},
			});
		},
		[createAnalyticsEvent],
	);

	return (
		<>
			{showTopSection && (
				<Section>
					{showManageApps && (
						<LinkItem
							iconBefore={<SettingsIcon label="" spacing="spacious" color={token('color.icon')} />}
							href="/plugins/servlet/upm"
							target="_blank"
							onClick={(...args) => {
								fireClickEvent('manageApps');
								onCloseWithStopPropagation(...args);
							}}
						>
							{formatMessage(messages.manageApps)}
						</LinkItem>
					)}
					{showAppAuditLogs && (
						<LinkItem
							iconBefore={<SettingsIcon label="" spacing="spacious" color={token('color.icon')} />}
							href="/plugins/servlet/upm/log"
							onClick={(...args) => {
								onCloseWithStopPropagation(...args);
							}}
							target="_blank"
						>
							{formatMessage(messages.appAuditLogs)}
						</LinkItem>
					)}
					{showViewAppRequests && (
						<LinkItem
							iconBefore={<AppIcon label="" spacing="spacious" color={token('color.icon')} />}
							href={viewAppRequestsLink}
							target="_blank"
							onClick={(...args) => {
								fireClickEvent('viewAppRequests');
								onCloseWithStopPropagation(...args);
							}}
						>
							{formatMessage(messages.viewAppRequests)}
						</LinkItem>
					)}
				</Section>
			)}
			<HideL1MenuItemSection menuId={L1_MENU_ID.APPS} hasSeparator={showTopSection} />
		</>
	);
}
