/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { customQueueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customQueueRoute.tsx';
import { customPracticeQueueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueRoute.tsx';
import { customPracticeQueueBoardRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueBoardRoute.tsx';
import { customQueueBoardRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customQueueBoardRoute.tsx';
import { customQueueCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customQueueCalendarRoute.tsx';
import { customPracticeQueueCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueCalendarRoute.tsx';
import { customIssueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customIssueRoute.tsx';

export const useStylingPatch = () => {
	const route = useCurrentRoute();
	const [issueKey] = usePathParam('issueKey');

	if (!fg('jsm_views_inside_queues_-_main_flag')) return { shouldUseStylingPatch: false };

	const isNav3QueueRouteOld =
		!getWillShowNav4() &&
		(route.name === customQueueRoute.name ||
			(route.name === customPracticeQueueRoute.name && !issueKey));

	const isNav3QueueRouteNew =
		!getWillShowNav4() &&
		(route.name === customQueueRoute.name ||
			route.name === customPracticeQueueRoute.name ||
			// Queue Issue View need to be included in the styling patch, so when navigate from queue to issue view, the component won't be re-rendered
			// The container is not applied to the issue view, so the styling patch won't be applied to issue view
			route.name === customIssueRoute.name);

	const isNav3QueueRoute = fg('jsm_fix_queue_issue_view_navigate_back_state')
		? isNav3QueueRouteNew
		: isNav3QueueRouteOld;

	const isNav3BoardRoute =
		!getWillShowNav4() &&
		(route.name === customQueueBoardRoute.name ||
			route.name === customPracticeQueueBoardRoute.name);

	const isNav3CalendarRoute =
		!getWillShowNav4() &&
		(route.name === customQueueCalendarRoute.name ||
			route.name === customPracticeQueueCalendarRoute.name);

	return {
		shouldUseStylingPatch: isNav3QueueRoute || isNav3BoardRoute || isNav3CalendarRoute,
	};
};

export const QueueNav3StylingPatchContainer = ({ children }: { children: ReactNode }) => {
	const { shouldUseStylingPatch } = useStylingPatch();

	if (shouldUseStylingPatch || !fg('jsm_fix_queue_issue_view_navigate_back_state'))
		return (
			<div
				css={nav3PaddingStyles}
				data-testid="horizontal-nav-queue.controllers.styling-patch.queue-nav-3-styling-patch-container"
			>
				{children}
			</div>
		);

	return children;
};

const nav3PaddingStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '137px',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
});
