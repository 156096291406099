import isNil from 'lodash/isNil';
import {
	Observable,
	type GraphQLSingularResponse,
	type RequestParameters,
	type Variables,
	type GraphQLResponse,
	type CacheConfig,
} from 'relay-runtime';
import {
	performPostRequest,
	performPostRequestWithRetry,
	applyErrorHandling,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { retryOnError } from '@atlassian/jira-fetch/src/utils/retries.tsx';
import { captureErrors, isUnauthenticated } from '@atlassian/jira-relay-errors/src/index.tsx';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises/src/index.tsx';
import {
	QueryResponseCache,
	getQueryResponseCache as queryResponseCacheGetter,
} from '@atlassian/jira-relay-query-responses/src/index.tsx';
import {
	claimEarlyScriptPromise,
	getEarlyScriptPromiseKey,
} from '@atlassian/jira-relay-vendorless-utils/src/services/early-script-promise/index.tsx';
import { putExtensions } from '@atlassian/relay-capture-extensions';
import { getAggEndpoint, getRequestBody } from '@atlassian/relay-endpoint';
import { addTraceId } from '@atlassian/relay-traceid';
import { showUnauthenticatedFlag } from './services/error-flags/index.tsx';
import { getLoggingAndMetricsHelper } from './services/logging-and-metrics/index.tsx';
import {
	checkAuthentication,
	getTraceIdFromExtensions,
	blocklist,
	generateOptions,
	shouldRetry,
	getRetryAttemptsForQuery,
} from './utils.tsx';

const getQueryResponseCache = () =>
	fg('jfp_magma_fix_relay_query_cache_loading_from_ssr')
		? queryResponseCacheGetter()
		: QueryResponseCache;

export const fetch = (
	request: RequestParameters,
	variables: Variables,
	cacheConfig: CacheConfig,
): Observable<GraphQLResponse> => {
	const redirecting = checkAuthentication();
	if (redirecting)
		// Hang the request if we are redirecting - should be cleared on redirect
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return Observable.from(new Promise<GraphQLResponse>(() => {}));

	const { name: queryName, operationKind } = request;
	const loggingMetadata = {
		queryName,
		operationKind,
	};
	const loggingAndMetrics = getLoggingAndMetricsHelper(loggingMetadata);

	function augmentResponse(response: GraphQLSingularResponse) {
		addTraceId(queryName, getTraceIdFromExtensions(response));
		putExtensions(queryName, response.extensions);
	}

	function validateResponse(response: GraphQLSingularResponse) {
		// @ts-expect-error - TS2339 - Property 'errors' does not exist on type 'GraphQLSingularResponse'.
		const errors = response.errors || undefined;
		if (errors != null) {
			const requestId = response?.extensions?.gateway?.request_id;
			if (!blocklist.includes(queryName)) {
				loggingAndMetrics.logNonBreakingErrors(errors, requestId);
			}
			captureErrors(errors, { operationName: queryName, requestId });

			if (isUnauthenticated(errors)) {
				return false;
			}
		}
		return true;
	}

	function enforceNonNullability(response: GraphQLSingularResponse) {
		if (response.data && (response.data.jira === null || response.data.customerService === null)) {
			// Enforce non-nullability of the jira and customerService namespaces. This is an intermediary solution to
			// prevent server errors/gateway timeouts from wiping the Relay store. Eventually we'd like this
			// to be enforced at a schema level, see further discussion https://hello.atlassian.net/wiki/spaces/~236031707/pages/2034382845/Top+level+errors+for+namespaces+in+AGG+Relay
			// @ts-expect-error - TS2322 - Relay types do not allow null data for a response with errors, however this is valid according to the spec http://spec.graphql.org/June2018/#sec-Data
			response.data = null;
		}
	}

	const earlyScriptPromise =
		(!__SERVER__ && claimEarlyScriptPromise(getEarlyScriptPromiseKey(request, variables))) ||
		undefined;

	return Observable.create((sink) => {
		try {
			const requestID =
				'cacheID' in request && request.cacheID != null ? request.cacheID : request.id;

			const handleResponse = (response: GraphQLResponse) => {
				sink.next(response);
				if ('hasNext' in response && response.hasNext === true) {
					// The data is part of an incremental response; keep the observable alive.
				} else {
					// The data is final; can close the observable.
					sink.complete();
				}
			};

			const endpoint = getAggEndpoint(request, cacheConfig);
			const body = getRequestBody(request, variables);

			// On client...
			if (!__SERVER__ && requestID != null) {
				// read cache from window.SPA_STATE if available
				const ssrCache = getQueryResponseCache().get(requestID, variables);

				if (!isNil(ssrCache)) {
					getQueryResponseCache().delete(requestID, variables);
					handleResponse(ssrCache);
					return;
				}
			}

			const wrappedEarlyScriptPromise =
				earlyScriptPromise &&
				retryOnError<GraphQLSingularResponse>(() => applyErrorHandling(earlyScriptPromise), {
					retryFunc: () =>
						performPostRequest(
							endpoint,
							generateOptions(
								{
									body,
								},
								endpoint,
							),
						),
					retryPredicate: shouldRetry,
					retryAttempts: getRetryAttemptsForQuery(queryName, operationKind),
					onRetry: loggingAndMetrics.logErrorRetry,
				});

			// Throws HttpError
			const promise: Promise<GraphQLSingularResponse> =
				!__SERVER__ && wrappedEarlyScriptPromise !== undefined
					? wrappedEarlyScriptPromise
					: Promise.resolve(
							performPostRequestWithRetry(endpoint, {
								...generateOptions(
									{
										body,
									},
									endpoint,
								),
								retryPredicate: shouldRetry,
								retryAttempts: getRetryAttemptsForQuery(queryName, operationKind),
								onRetry: (error, attempt) => {
									loggingAndMetrics.logErrorRetry(error, attempt);
								},
							}),
						);

			// todo: need to adjust so that we can handle multipart responses, decode in to graphql payloads, then push through sink appropriately
			const responses = Observable.from(promise);

			// Recreate a bizarre behaviour that will cause the observable to hang indefinitely when the user is not authenticated, presumably because we redirect the user to log in...
			// This should be revised; see https://jplat.atlassian.net/browse/MAGMA-3087
			let responseShouldHang = false;
			// todo: revisit contract for QueryResponseCache, would help clean this up a bit.
			let resolveFirstResponse: (val: GraphQLSingularResponse) => void;
			// todo: eventually will be used, once sufficient test coverage added to capture the scenario, QueryPromisesMap gets updated, other dust settles
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			let rejectFirstResponse: (err: Error) => void;
			let firstResponseUsed = false;
			const firstResponse: Promise<GraphQLSingularResponse> = new Promise((resolve, reject) => {
				resolveFirstResponse = resolve;
				rejectFirstResponse = reject;
			});

			responses.subscribe({
				start: () => {
					if (requestID != null) {
						QueryPromisesMap.set(requestID, firstResponse);
					}
				},
				next: (response) => {
					if (__SERVER__ && firstResponseUsed) {
						// todo: write so that it gets flushed to client
					}

					if (!firstResponseUsed) {
						firstResponseUsed = true;
						// send response to query promises map, so that
						// * on server, SSR SPA render can unblock once all are received
						// * on client, ...?
						resolveFirstResponse(response);

						augmentResponse(response);
						if (!validateResponse(response)) {
							//  Short circuit operation and handle the unauthorized errors.
							showUnauthenticatedFlag(queryName);
							responseShouldHang = true;
							return;
						}
						enforceNonNullability(response);

						// write cache query response on SSR
						if (__SERVER__ && requestID != null) {
							getQueryResponseCache().setWithMetadata(
								requestID,
								variables,
								response,
								loggingMetadata,
							);
						}
					}

					// pipe data through the relay-fetch observable itself
					handleResponse(response);
				},
				error: (error: Error) => {
					if (!firstResponseUsed) {
						firstResponseUsed = true;
						// todo: check control flows for fetch failure cases on server + client. Seems weird that
						// the previous construction of the function would not throw UnhandledPromiseRejectionWarning despite
						// the passed reference not including a catch
						// rejectFirstResponse(error);
					}
					loggingAndMetrics.logError(error);
					sink.error(error);
				},
				complete: () => {
					if (responseShouldHang) return;
					sink.complete();
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			loggingAndMetrics.logError(error);
			sink.error(error);
		}
	});
};
