import React from 'react';
import { QueueNav3StylingPatchContainer } from '@atlassian/jira-horizontal-nav-queue/src/controllers/styling-patch/index.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

export const QueueLoadingSkeleton = () => {
	return getWillShowNav4() && !fg('jsm_fix_queue_issue_view_navigate_back_state') ? (
		<Spinner />
	) : (
		<QueueNav3StylingPatchContainer>
			<Spinner />
		</QueueNav3StylingPatchContainer>
	);
};
