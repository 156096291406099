import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const roadmapViewClassicEntrypoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() =>
			import(
				/* webpackChunkName: "async-classic-roadmap-entrypoint" */ './src/view/classic/index.tsx'
			),
	),
	getPreloadProps: () => ({}),
});
