import React, { useRef, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import AddIcon from '@atlaskit/icon/utility/add';
import { Flex } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectsAnalyticsL2 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l2/index.tsx';
import { CommonNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/common-nav-menu-button/main.tsx';
import { ProjectActionMenu } from '@atlassian/jira-navigation-project-action-menu/src/ui/index.tsx';
import type { ProjectActions$key } from '@atlassian/jira-relay/src/__generated__/ProjectActions.graphql';
import { BoardCreate } from '@atlassian/jira-software-board-create/src/ui/index.tsx';
import { ThemePicker } from '@atlassian/jira-project-theme-components/src/ui/theme-picker/ThemePicker.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import { createRouterSelector } from '@atlassian/react-resource-router';
import { isCompanyManagedSoftwareProject } from './utils.tsx';
import messages from './messages.tsx';

export type Props = {
	projectFragment: ProjectActions$key;
	isCorePremiumUserSeat?: boolean;
};

export const useRouterMatch = createRouterSelector((state) => state.match);

export const ProjectActions = ({ projectFragment, isCorePremiumUserSeat = false }: Props) => {
	const { formatMessage } = useIntl();
	const {
		sendMeatBallDropDownOnProjectsAnalyticsEvent,
		sendCreateBoardOnBoardMenuItemAnalyticsEvent,
	} = useProjectsAnalyticsL2();
	const match = getIsSoftwareThemingEnabled()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useRouterMatch()
		: undefined;
	const createBoardButtonRef = useRef<HTMLButtonElement | null>(null);
	const { hasSoftwareTheming } = getIsSoftwareThemingEnabled()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSoftwareProjectTheming()
		: { hasSoftwareTheming: false };

	const project = useFragment<ProjectActions$key>(
		graphql`
			fragment ProjectActions on JiraProject {
				projectKey: key @required(action: THROW)
				projectId @required(action: THROW)
				isFavourite
				projectStyle
				projectType @required(action: THROW)
				avatar {
					large
				}
				editPermission: action(type: EDIT_PROJECT_CONFIG) {
					canPerform
				}
			}
		`,
		projectFragment,
	);

	const projectActionMenu = useMemo(() => {
		const canEditProjectConfig = project?.editPermission?.canPerform ?? false;
		const currentProjectKey = match?.params?.projectKey || match?.params?.issueKey?.split('-')[0];
		const canChangeTheme =
			currentProjectKey === project.projectKey && canEditProjectConfig && hasSoftwareTheming;

		return (
			<ProjectActionMenu
				onClick={() => {
					const { isFavourite, projectId, projectType } = project;

					sendMeatBallDropDownOnProjectsAnalyticsEvent({
						starred: isFavourite ?? false,
						recent: !isFavourite,
						projectId,
						projectType,
					});
				}}
				additionalMenuItems={
					canChangeTheme &&
					getIsSoftwareThemingEnabled() && (
						<ThemePicker projectId={Number(project.projectId)} withAdaptiveColorTheme />
					)
				}
				projectKey={project.projectKey}
				isCorePremiumUserSeat={isCorePremiumUserSeat}
			/>
		);
	}, [
		project,
		match,
		hasSoftwareTheming,
		isCorePremiumUserSeat,
		sendMeatBallDropDownOnProjectsAnalyticsEvent,
	]);

	return (
		<>
			<Flex gap="space.050" alignItems="center">
				{isCompanyManagedSoftwareProject(project.projectStyle, project.projectType) && (
					<BoardCreate
						projectId={project.projectId}
						projectAvatar={project.avatar?.large || undefined}
						projectKey={project.projectKey} // remove when clean up fix_board_url_value_in_graphql_apis
						triggerRef={createBoardButtonRef}
						renderTrigger={({ triggerRef, ...props }) => {
							// callback to set button element to local ref.
							// to be used as prop for setting focus back to button after modal close.
							const setRef = (buttonElement: HTMLButtonElement | null) => {
								createBoardButtonRef.current = buttonElement;
								if (typeof triggerRef === 'function') {
									triggerRef(buttonElement);
								}
							};

							return (
								<Tooltip content={formatMessage(messages.createBoard)}>
									<CommonNavMenuButton
										{...props}
										onClick={(event) => {
											const { onClick } = props;
											onClick?.(event);
											sendCreateBoardOnBoardMenuItemAnalyticsEvent({
												starred: project.isFavourite ?? false,
												recent: !project.isFavourite,
												projectId: project.projectId,
												projectType: project.projectType,
											});
										}}
										icon={AddIcon}
										label={formatMessage(messages.createBoard)}
										ref={setRef}
									/>
								</Tooltip>
							);
						}}
					/>
				)}
			</Flex>
			{projectActionMenu}
		</>
	);
};
