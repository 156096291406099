import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import businessTimelineParameters from '@atlassian/jira-relay/src/__generated__/src_jiraHarmonisedBusinessTimelineQuery$parameters';
import timelineParameters from '@atlassian/jira-relay/src/__generated__/src_timelineQuery$parameters';

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const and = (clauses: string[]): string => {
	return clauses
		.map((clause) => clause.trim())
		.filter((clause) => clause.length > 0)
		.join(' AND ');
};

const getProjectJql = (projectKey: string) =>
	projectKey.length > 0 ? `project = '${projectKey}'` : '';

const getSearchTextJql = (text?: string) => {
	if (text === undefined || text.trim() === '') {
		return '';
	}
	const sanitize = (value: string) =>
		value.replace(/\\/g, '\\\\').replace(/'/g, "\\'").replace(/\t/g, ' ');

	const issueKeyRegex = new RegExp('^([A-Z][A-Z0-9_]+-\\d+)$');
	const japaneseAndChineseRegex =
		/[\p{Script_Extensions=Hiragana}\p{Script_Extensions=Katakana}\p{Script_Extensions=Han}]/u;

	let search = sanitize(text);

	if (issueKeyRegex.test(text)) {
		return `(summary ~ '${search}*' OR description ~ '${search}*' OR key = '${search}')`;
	}

	// if the text does not contain Chinese or Japanese characters (which are not tokenized),
	// and does not end with a space character
	// make it a wildcard search
	if (!japaneseAndChineseRegex.test(text) && !text.endsWith(' ')) {
		search = `${search}*`;
	}

	return `(summary ~ '${search}' OR description ~ '${search}')`;
};

const buildIssuesSearchInput = (filter = '', text = '', projectKey = '') => {
	const search = getSearchTextJql(text);
	const project = getProjectJql(projectKey);
	return {
		jql: and([search, filter, project]),
	};
};

export const timelineEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-harmonised-business-timeline" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext: { cloudId } }: EntryPointRouteParams) => {
		const namespace = 'ISSUE_NAVIGATOR';
		const projectKey = context.match.params.projectKey;
		const { filter, text } = context.query;

		if (projectKey == null) {
			throw new Error('Project key missing');
		}

		const fieldSetIds = ['issuetype', 'issuekey', 'summary', 'Start date[Date]', 'duedate'];

		return {
			queries: {
				businessTimelineQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters: businessTimelineParameters,
					variables: {
						cloudId,
						projectKey,
					},
				},
				timelineQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters: timelineParameters,
					variables: {
						amountOfColumns: 500,
						cloudId,
						fieldSetIds,
						fieldSetsInput: { fieldSetIds },
						filterId: null,
						first: 50,
						issueSearchInput: buildIssuesSearchInput(filter, text, projectKey),
						namespace,
						projectKey,
						shouldQueryFieldSetsById: false,
						shouldQueryHasChildren: true,
						staticViewInput: {
							isGroupingEnabled: false,
						},
						viewConfigInput: { viewInput: { namespace } },
						viewId: 'list_default',
					},
				},
			},
		};
	},
});
