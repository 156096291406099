import { useEffect, useMemo, useState } from 'react';
import {
	addRouteListener,
	getRouterState,
	createRouterSelector,
} from '@atlassian/react-resource-router/src/controllers/router-store';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	getIsSoftwareThemingEnabled,
	getIsSoftwareThemingM2Enabled,
} from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	SOFTWARE_THEME_ROUTES,
	SOFTWARE_THEME_ROUTES_M2,
	SOFTWARE_FULL_THEME_ROUTES,
	SOFTWARE_FULL_THEME_ROUTES_M2,
} from './constants.tsx';

export const getSoftwareProjectThemingState = (
	routeName: string,
	projectType?: string,
): {
	hasSoftwareTheming: boolean;
	isSoftwareFullTheming: boolean;
	isSoftwareProject: boolean;
} => {
	if (!getIsSoftwareThemingEnabled()) {
		return {
			hasSoftwareTheming: false,
			isSoftwareFullTheming: false,
			isSoftwareProject: false,
		};
	}

	const isSoftwareCalendarRoute =
		routeName === 'software-calendar' || routeName === 'software-calendar-classic';

	const isSoftwareProject = projectType === SOFTWARE_PROJECT;
	const hasSoftwareTheming = getIsSoftwareThemingM2Enabled()
		? isSoftwareProject && SOFTWARE_THEME_ROUTES_M2.includes(routeName) && !isSoftwareCalendarRoute
		: isSoftwareProject && SOFTWARE_THEME_ROUTES.includes(routeName) && !isSoftwareCalendarRoute;
	const isSoftwareFullTheming = getIsSoftwareThemingM2Enabled()
		? hasSoftwareTheming && SOFTWARE_FULL_THEME_ROUTES_M2.includes(routeName)
		: hasSoftwareTheming && SOFTWARE_FULL_THEME_ROUTES.includes(routeName);

	return {
		hasSoftwareTheming,
		isSoftwareFullTheming,
		isSoftwareProject,
	};
};

export const useCurrentRoute = createRouterSelector((state) => state.route.name);

export const useSoftwareProjectThemingOld = () => {
	if (!getIsSoftwareThemingEnabled()) {
		return {
			hasSoftwareTheming: false,
			isSoftwareFullTheming: false,
			isSoftwareProject: false,
		};
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data: projectData } = useProjectContext();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const currentRoute = useCurrentRoute();

	const isSoftwareProject = projectData?.projectType === SOFTWARE_PROJECT;

	// temporarily disable theming for calendar
	// the logic matches with the one in src/packages/platform/project-theme/page-container/src/controllers/project-theme/index.tsx
	const isSoftwareCalendarRoute =
		currentRoute === 'software-calendar' || currentRoute === 'software-calendar-classic';

	const hasSoftwareTheming = getIsSoftwareThemingM2Enabled()
		? isSoftwareProject &&
			SOFTWARE_THEME_ROUTES_M2.includes(currentRoute) &&
			!isSoftwareCalendarRoute
		: isSoftwareProject && SOFTWARE_THEME_ROUTES.includes(currentRoute) && !isSoftwareCalendarRoute;
	const isSoftwareFullTheming = getIsSoftwareThemingM2Enabled()
		? hasSoftwareTheming && SOFTWARE_FULL_THEME_ROUTES_M2.includes(currentRoute)
		: hasSoftwareTheming && SOFTWARE_FULL_THEME_ROUTES.includes(currentRoute);

	return {
		hasSoftwareTheming,
		isSoftwareFullTheming,
		isSoftwareProject,
	};
};

const useSoftwareProjectThemingNew = (): {
	hasSoftwareTheming: boolean;
	isSoftwareFullTheming: boolean;
	isSoftwareProject: boolean;
} => {
	const { data: projectData } = useProjectContext();
	const firstRouterState = useMemo(() => getRouterState(), []);
	const [softwareProjectThemingState, setSoftwareProjectThemingState] = useState(() =>
		getSoftwareProjectThemingState(firstRouterState.route.name, projectData?.projectType),
	);

	useEffect(() => {
		const unsubscribe = addRouteListener(({ route }) => {
			setSoftwareProjectThemingState((prevState) => {
				const nextState = getSoftwareProjectThemingState(route.name, projectData?.projectType);
				if (
					nextState.hasSoftwareTheming !== prevState.hasSoftwareTheming ||
					nextState.isSoftwareFullTheming !== prevState.isSoftwareFullTheming ||
					nextState.isSoftwareProject !== prevState.isSoftwareProject
				) {
					return nextState;
				}
				return prevState;
			});
		});

		return () => unsubscribe();
	}, [projectData?.projectType]);

	return softwareProjectThemingState;
};

export const useSoftwareProjectTheming = functionWithCondition(
	() => fg('reduce_navigation_rerenders_1'),
	useSoftwareProjectThemingNew,
	useSoftwareProjectThemingOld,
);
