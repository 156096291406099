import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import type { GraphQLSingularResponse } from 'relay-runtime';
import { getATLContextDomain } from '@atlassian/atl-context';
import { getInstance } from '@atlassian/heartbeat';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import { RETRY_ATTEMPTS_LIMIT } from '@atlassian/jira-fetch/src/utils/retries.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import RelayExperimentalApis from '@atlassian/jira-relay-experimental-apis/src/index.tsx';

let redirecting = false;

const redirect = (): void => {
	const identity = getATLContextDomain('id');

	// Hardcoding to Jira as this will be the only adopter of this service for now.
	const redirectUrl = `https://${identity}/login?application=jira&continue=${encodeURIComponent(
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.href,
	)}`;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.href = redirectUrl;
};

/**
 * Checks if the user is authenticated.
 * @returns true if the user will be redirected to login page.
 * @sideEffect sets the redirecting flag to true if the user is not authenticated.
 * @sideEffect changes window.location.href to the login page if the user is not authenticated.
 */
export const checkAuthentication = (): boolean => {
	if (process.env.NODE_ENV !== 'development') {
		if (!__SERVER__ && !redirecting) {
			const heartbeatService = getInstance();
			if (heartbeatService.isInitialized() && !heartbeatService.isAuthenticated()) {
				redirect();
				// Set flag to prevent multiple redirects
				redirecting = true;
			}
		}
	}
	return redirecting;
};

// Silence error logs from queries in this list
export const blocklist = ['issueAggQuery'];

export const getRetryAttemptsForQuery = (queryName: string, operationKind: string) => {
	if (operationKind === 'query') {
		return RETRY_ATTEMPTS_LIMIT;
	}

	return 0;
};

export const getTraceIdFromExtensions = ({
	extensions,
}: GraphQLSingularResponse): string | undefined => {
	if (
		extensions !== undefined &&
		extensions.gateway !== undefined &&
		extensions.gateway !== null &&
		typeof extensions.gateway.request_id === 'string'
	) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return extensions.gateway.request_id as string;
	}
	return undefined;
};

/**
 * Given a set of options, produce a new set of options for consumption which contains
 * a combination of the given set and the default options
 * @param {RequestOptions} options
 * @param url
 * @returns RequestOptions
 */
export const generateOptions = (options: RequestInit = {}, url: string | null = null) => {
	// the token fetcher returns undefined if no cookie value was found, also in SSR cookies are unavailable
	const csrfToken = !__SERVER__ ? getXsrfToken() : undefined;

	// If we don't get a token, there is no point trying to add the csrf header
	const csrfTokenHeader = isNil(csrfToken) ? {} : { 'atl-xsrf-token': csrfToken };

	return merge(
		{}, // New object to ensure the other options aren't modified
		{
			// We have to provide general options here or the headers we provide will override the default ones found
			// here /src/packages/platform/utils/fetch/src/utils/requests.js due to a shallow merge
			...getDefaultOptions(fg('obsrve-2239-traceid-errorflag-issue-transition') ? url : null),
			...options,
		},
		{
			headers: {
				'atl-client-name': 'jira-frontend',

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				'atl-client-version': `${window?.BUILD_KEY ?? ''}::${window?.SSR_BUILD_KEY ?? ''}`,
				'X-ExperimentalApi': RelayExperimentalApis.join(','),
				...csrfTokenHeader,
			},
		},
	);
};

export const shouldRetry = (error: Error): boolean => {
	if (error instanceof FetchError) {
		return [502, 503, 504].includes(error.statusCode);
	}
	return false;
};
