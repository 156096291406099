import type { Route } from '@atlassian/react-resource-router';
import HealthcheckPage from '@atlassian/jira-applinks-extra-auth/src/index.tsx';
import { applinksExtraAuthHealthcheckRoute } from '@atlassian/jira-router-routes-admin-pages-applinks-routes/src/applinksExtraAuthHealthcheckRoute.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { conditionalPostOfficeJiraTopBannerResource } from '@atlassian/jira-router-resources-post-office/src/services/index.tsx';

export const applinksExtraAuthHealthcheckRouteEntry: Route = createEntry(
	applinksExtraAuthHealthcheckRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'applinks-extra-auth-healthcheck',
		component: componentWithFG(
			'applinks-789-extra-auth-healthcheck-frontend',
			HealthcheckPage,
			ErrorPagesNotFound,
		),
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			conditionalPostOfficeJiraTopBannerResource,
		],
		canTransitionIn: () => fg('applinks-789-extra-auth-healthcheck-frontend'),
		forPaint: [LazyAtlassianNavigation],
		meta: {
			reporting: {
				id: 'Applinks',
				packageName: 'jiraAdminPagesApplinks',
				teamName: 'olympus',
			},
		},
	}),
);
