/**
 * @generated SignedSource<<58eefedfc0be82c49e0b43a97eaa9242>>
 * @relayHash 17c134d894620f1c421b59ff04b5022d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c5b53bba13d4edd9584d3e5ae8520c04e6bcadb47f3d05d96d73c7ba53c49c74

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentPerformanceQuery } from './srcVirtualAgentPerformanceQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';
import roiTeaserM1Enabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-roi-teaser-m1-enabled.relayprovider';
import vaStatusHeaderEnabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-status-header.relayprovider';
import vsaAdoptionStage1Enabled_provider from '@atlassian/jira-relay-provider/src/vsa-adoption-stage-1.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentPerformanceQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c5b53bba13d4edd9584d3e5ae8520c04e6bcadb47f3d05d96d73c7ba53c49c74",
    "metadata": {},
    "name": "srcVirtualAgentPerformanceQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaroiteaserm1enabledrelayprovider": roiTeaserM1Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvastatusheaderrelayprovider": vaStatusHeaderEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcvsaadoptionstage1relayprovider": vsaAdoptionStage1Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;
