import React, { useEffect, useMemo, useState, type ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';
import PagesIcon from '@atlaskit/icon/core/pages';
import {
	BOOLEAN_LITERAL_FALSE,
	IDENTIFIER_ACKNOWLEDGED,
	IDENTIFIER_ASSIGNEE,
	IDENTIFIER_IS_SEEN,
	IDENTIFIER_STATUS,
	LOGICAL_OPERATOR_AND,
	OPERATOR_EQUAL_TO,
	STRING_LITERAL_STATUS_CLOSED,
	STRING_LITERAL_STATUS_OPEN,
} from '@atlassian/eoc-query-editor';
import { useIntl } from '@atlassian/jira-intl';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	LEVEL_THREE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	ENTITY_ID,
	MENU_ID_OPERATIONS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { toMenuIdFromMenuIdList } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/utils/menu-id/index.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	AlertsMenuItemContentNew$key,
	AlertsMenuItemContentNew$data,
} from '@atlassian/jira-relay/src/__generated__/AlertsMenuItemContentNew.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { useQueryParam } from '@atlassian/react-resource-router';
import commonMessages from '../../../common/messages.tsx';
import messages from '../../messages.tsx';
import { AlertsFlyoutMenuItem } from './flyout-menu/index.tsx';
import { SavedSearchActionButtons } from './action-button/index.tsx';

export function AlertsMenuItemContentNew({ queryRef }: { queryRef: AlertsMenuItemContentNew$key }) {
	const data = useFragment<AlertsMenuItemContentNew$key>(
		graphql`
			fragment AlertsMenuItemContentNew on Query {
				opsgenie @required(action: THROW) {
					savedSearches(cloudId: $cloudId) @required(action: THROW) @optIn(to: "opsgenie-beta") {
						sharedWithMe @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							alertSearchQuery @required(action: THROW)
						}
						createdByMe @required(action: THROW) {
							id @required(action: THROW)
							name @required(action: THROW)
							alertSearchQuery @required(action: THROW)
						}
					}
				}
			}
		`,
		queryRef,
	);

	const [alertSearchQuery] = useQueryParam('query');
	const [selectedFilter, setSelectedFilter] = useState('');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const accountId = useAccountId();
	const [viewType] = useQueryParam('view');
	const { icon: pagesIcon } = useFormattedMessageAndIcon(commonMessages.alerts, PagesIcon);

	const { sharedWithMe, createdByMe } = data.opsgenie.savedSearches;

	const defaultFilters = useMemo(
		() => [
			{ id: 'all', name: formatMessage(messages.allSearch), alertSearchQuery: '' },
			{
				id: 'open',
				name: formatMessage(messages.openSearch),
				alertSearchQuery: `${IDENTIFIER_STATUS}${OPERATOR_EQUAL_TO} ${STRING_LITERAL_STATUS_OPEN}`,
			},
			{
				id: 'closed',
				name: formatMessage(messages.closedSearch),
				alertSearchQuery: `${IDENTIFIER_STATUS}${OPERATOR_EQUAL_TO} ${STRING_LITERAL_STATUS_CLOSED}`,
			},
			{
				id: 'unacked',
				name: formatMessage(messages.unackedSearch),
				alertSearchQuery: `${IDENTIFIER_STATUS}${OPERATOR_EQUAL_TO} ${STRING_LITERAL_STATUS_OPEN} ${LOGICAL_OPERATOR_AND} ${IDENTIFIER_ACKNOWLEDGED}${OPERATOR_EQUAL_TO} ${BOOLEAN_LITERAL_FALSE}`,
			},
			{
				id: 'notSeen',
				name: formatMessage(messages.notSeenSearch),
				alertSearchQuery: `${IDENTIFIER_IS_SEEN}${OPERATOR_EQUAL_TO} ${BOOLEAN_LITERAL_FALSE}`,
			},
			{
				id: 'assignedToMe',
				name: formatMessage(messages.assignedToMeSearch),
				alertSearchQuery: `${IDENTIFIER_ASSIGNEE}${OPERATOR_EQUAL_TO} "${accountId || 'me'}"`,
			},
		],
		[formatMessage, accountId],
	);
	const defaultFilterMenuIds = getParentMenuId(defaultFilters);

	const defaultFilterWithComponentList = getComponentAddedSearchObjects(
		defaultFilters,
		pagesIcon,
		viewType,
		'default',
		setSelectedFilter,
		createAnalyticsEvent,
	);
	const sharedWithMeWithComponentList = getComponentAddedSearchObjects(
		sharedWithMe,
		pagesIcon,
		viewType,
		'sharedWithMe',
		setSelectedFilter,
		createAnalyticsEvent,
	);
	const createdByMeWithComponentList = getComponentAddedSearchObjects(
		createdByMe,
		pagesIcon,
		viewType,
		'createdByMe',
		setSelectedFilter,
		createAnalyticsEvent,
	);
	const allFiltersWithComponentList = useMemo(
		() => [
			...defaultFilterWithComponentList,
			...sharedWithMeWithComponentList,
			...createdByMeWithComponentList,
		],
		[createdByMeWithComponentList, defaultFilterWithComponentList, sharedWithMeWithComponentList],
	);

	const getSelectedFilterComponentIfNotDefault = useMemo(
		() => (filterId: string) => {
			if (defaultFilters.find((item) => item.id === filterId)) {
				return;
			}

			const foundComponent = [
				...sharedWithMeWithComponentList,
				...createdByMeWithComponentList,
			].find((item) => item.id === filterId)?.component;
			return foundComponent;
		},
		[createdByMeWithComponentList, defaultFilters, sharedWithMeWithComponentList],
	);

	useEffect(() => {
		if (selectedFilter === '') {
			for (const item of allFiltersWithComponentList) {
				// FIXME: This approach is not ideal. We should somehow use id instead of the alertSearchQuery.
				// https://ifountain.atlassian.net/browse/ALX-9535
				if (item.alertSearchQuery === alertSearchQuery) {
					setSelectedFilter(item.id);
					break;
				}
			}
		}
	}, [alertSearchQuery, allFiltersWithComponentList, selectedFilter]);

	return (
		<UFOSegment name="nav4.sidebar.operations.alerts">
			{/* todo: enable this when starred searches are done.
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.starred)}</MenuSectionHeading>
						<MenuLinkItem
							isSelected={selectedFilter === 'allAlerts'}
							elemBefore={pagesIcon}
							href="/jira/ops/alerts"
							onClick={() => setSelectedFilter('allAlerts')}
						>
							{formatMessage(messages.allAlerts)}
						</MenuLinkItem>
						<MenuLinkItem
							isSelected={selectedFilter === 'myAlerts'}
							elemBefore={pagesIcon}
							href="/jira/ops/alerts"
							onClick={() => setSelectedFilter('myAlerts')}
						>
							{formatMessage(messages.myAlerts)}
						</MenuLinkItem>
					</MenuSection> */}
			<Nav4ExpandableMenuItem
				menuId={defaultFilterMenuIds}
				onExpansionToggle={(hasExpanded) => {
					if (hasExpanded) {
						fireUIAnalytics(createAnalyticsEvent({}), {
							action: 'clicked',
							actionSubject: NAVIGATION_ITEM,
							actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
							attributes: {
								componentType: FIXED_ITEM,
								level: LEVEL_TWO,
								itemId: 'defaultFilters',
								parentItemId: 'alerts',
							},
						});
					}
				}}
			>
				<ExpandableMenuItemTrigger testId={getTestId(defaultFilterMenuIds)}>
					{formatMessage(messages.defaultFilters)}
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					{defaultFilters.map((item) => (
						<Nav4MenuLinkItem
							elemBefore={pagesIcon}
							key={item.id}
							href={`/jira/ops/alerts?view=${viewType ?? 'list'}${item.alertSearchQuery ? `&query=${item.alertSearchQuery}` : ''}`}
							menuId={ENTITY_ID.ALARM_QUEUE(item.alertSearchQuery)}
							onClick={() => {
								setSelectedFilter(item.id);

								fireUIAnalytics(createAnalyticsEvent({}), {
									action: 'clicked',
									actionSubject: NAVIGATION_ITEM,
									actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
									attributes: {
										componentType: FIXED_ITEM,
										level: LEVEL_THREE,
										itemId: item.id,
										parentItemId: 'defaultFilters',
										itemType: 'defaultFilter',
									},
								});
							}}
						>
							{item.name}
						</Nav4MenuLinkItem>
					))}
				</ExpandableMenuItemContent>
			</Nav4ExpandableMenuItem>

			<>{getSelectedFilterComponentIfNotDefault(selectedFilter)}</>

			<AlertsFlyoutMenuItem
				defaultFilterWithComponentList={defaultFilterWithComponentList}
				sharedWithMeWithComponentList={sharedWithMeWithComponentList}
				createdByMeWithComponentList={createdByMeWithComponentList}
				allFiltersWithComponentList={allFiltersWithComponentList}
			/>
		</UFOSegment>
	);
}

type AlertFilters = AlertsMenuItemContentNew$data['opsgenie']['savedSearches']['createdByMe'];

function getParentMenuId(arr?: AlertFilters) {
	return toMenuIdFromMenuIdList((arr || []).map((i) => ENTITY_ID.ALARM_QUEUE(i.alertSearchQuery)));
}

function getComponentAddedSearchObjects(
	arrayList: AlertFilters,
	pagesIcon: ReactElement,
	viewType: string | undefined,
	savedSearchType: 'default' | 'sharedWithMe' | 'createdByMe',
	setSelectedFilter: React.Dispatch<React.SetStateAction<string>>,
	createAnalyticsEvent: ReturnType<typeof useAnalyticsEvents>['createAnalyticsEvent'],
): Array<AlertFilters[number] & { component: ReactElement }> {
	return arrayList.map((item) => ({
		...item,
		component: (
			<Nav4MenuLinkItem
				elemBefore={pagesIcon}
				key={item.id}
				href={`/jira/ops/alerts?view=${viewType ?? 'list'}${item.alertSearchQuery ? `&query=${item.alertSearchQuery}` : ''}`}
				menuId={ENTITY_ID.ALARM_QUEUE(item.alertSearchQuery)}
				onClick={() => {
					setSelectedFilter(item.id);

					fireUIAnalytics(createAnalyticsEvent({}), {
						action: 'clicked',
						actionSubject: NAVIGATION_ITEM,
						actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
						attributes: {
							componentType: FIXED_ITEM,
							level: LEVEL_THREE,
							itemId: item.id,
							parentItemId: 'defaultFilters',
							itemType: 'defaultFilter',
						},
					});
				}}
				{...(savedSearchType === 'createdByMe' && {
					actionsOnHover: <SavedSearchActionButtons savedSearch={item} />,
				})}
			>
				{item.name}
			</Nav4MenuLinkItem>
		),
	}));
}
