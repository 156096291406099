import type {
	GraphQLResponse,
	Observable,
	RequestParameters,
	Variables,
	CacheConfig,
} from 'relay-runtime';
import { fg } from '@atlassian/jira-feature-gating';

import { fetch as multiFetch } from './multi-fetch.tsx';
import { fetch as singleFetch } from './single-fetch.tsx';

export const fetch = (
	request: RequestParameters,
	variables: Variables,
	cacheConfig: CacheConfig,
): Observable<GraphQLResponse> => {
	return fg('jfp-magma-support-defer')
		? multiFetch(request, variables, cacheConfig)
		: singleFetch(request, variables, cacheConfig);
};
