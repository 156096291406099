import type { DocNode } from '@atlaskit/adf-schema';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { JiraPlaybookAri } from '@atlassian/ari/jira/playbook';
import type { JiraPlaybookStepType } from '@atlassian/jira-relay/src/__generated__/PlaybookItemDetail.graphql';
import { usePathParam } from '@atlassian/react-resource-router';

import { getProjectSettingsPageUrl } from '@atlassian/jira-servicedesk-common/src/navigation/settings/index.tsx';
import type { StepData, Step, StepsDetail } from '../types.tsx';
import { NEW_PLAYBOOK_IDENTIFIER } from '../constants.tsx';

export const getStepData = (
	id: string,
	name: string,
	type: JiraPlaybookStepType,
	index: number,
): StepData => {
	return { stepId: id, name, index, type };
};

export const isStep = (data: Record<string | symbol, unknown>): data is StepData => {
	if (data) {
		const { type } = data;
		return type === 'INSTRUCTIONAL_RULE' || type === 'AUTOMATION_RULE';
	}
	return false;
};

export const getJiraPlaybookARI = (cloudId: string, activationId: string, playbookId: string) => {
	return JiraPlaybookAri.create({
		siteId: cloudId,
		activationId,
		playbookId,
	}).toString();
};

export const useIsNewPlaybook = (): boolean => {
	const [playbookId = ''] = usePathParam('playbookId');

	return playbookId.includes(NEW_PLAYBOOK_IDENTIFIER);
};

export const getJiraPlaybookIdFromARI = (playbookAri: string): string => {
	return JiraPlaybookAri.parse(playbookAri).playbookId;
};

export const useReturnToListUrl = (): [string] => {
	const [projectKey = ''] = usePathParam('projectKey');

	const playbookListLink = getProjectSettingsPageUrl(projectKey, 'playbooks');

	return [playbookListLink];
};

export const getIsDescriptionEmpty = (description?: DocNode) => {
	if (description && Object.keys(description).length > 0) {
		return JSON.stringify(description) === JSON.stringify(getEmptyADF());
	}
	return true;
};

export const getStepsOrder = (data: Step[]): string[] => {
	return data.map((step) => step.stepId);
};

export const getStepsDetail = (data: Step[]): StepsDetail => {
	const acc: StepsDetail = {};
	data.forEach((step) => {
		acc[step.stepId] = step;
	});
	return acc;
};
