import React, { useEffect, type ReactNode } from 'react';
import { render } from 'react-dom';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
// eslint-disable-next-line jira/restricted/react-18
import { createRoot, hydrateRoot } from 'react-dom/client';
import getUFORouteName from '@atlaskit/react-ufo/route-name';
import { collectSSRPlaceholderDimensions } from '@atlaskit/react-ufo/ssr-scripts';
import traceUFOPageLoad from '@atlaskit/react-ufo/trace-pageload';
import { useUFOTransitionCompleter } from '@atlaskit/react-ufo/trace-transition';
import { getVCObserver } from '@atlaskit/react-ufo/vc';
import { browserMetrics } from '@atlassian/browser-metrics';
import { reportBootstrapError } from '@atlassian/jira-bootstrap-sla-reporter/src/index.tsx';
import { initBrowserMetrics3 } from '@atlassian/jira-browser-metrics';
import { CAPABILITY_HEADER_NAME } from '@atlassian/jira-capabilities/src/constants.tsx';
import { setCapability } from '@atlassian/jira-capabilities/src/services/active-capability-tracker/index.tsx';
import '@atlassian/jira-global-side-effects/src/index.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import {
	EntryPointPlaceholderContext,
	getPlaceholdersFromHTML,
} from '@atlassian/jira-entry-point-placeholder/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { initPlatformFeatureFlags } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { NonCritical } from '@atlassian/jira-non-critical/src/NonCriticalComponent.tsx';
import { Spa } from '@atlassian/jira-page-container-v2/src/ui/spa/index.tsx';
import { setMatchedRoute } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { StrictMode } from '@atlassian/jira-react-strict-mode/src/StrictMode.tsx';
import { JiraRouterLinkConfiguration } from '@atlassian/jira-router-components/src/ui/router-link-config/index.tsx';
import { Router } from '@atlassian/jira-router-components/src/ui/router/index.tsx';
import SessionTracker from '@atlassian/jira-session-tracker/src/view/index.tsx';
import {
	measureInitialPageLoadTiming,
	setInitialPageLoadTimingFromPerformanceMarks,
	stopInitialPageLoadTimingFromPerformanceMarkStart,
} from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { addInitialPageLoadTimings } from '@atlassian/jira-spa-performance-breakdown/src/utils/timings-from-eval-timings/index.tsx';
import { setPrefetchStartMark } from '@atlassian/jira-spa-transition-resources-tracker/src/utils/prefetch-timing/index.tsx';
import createSpaHistory from '@atlassian/jira-spa/src/services/create-spa-history/index.tsx';
import { getSpaRouterContext } from '@atlassian/jira-spa/src/services/spa-router-context/index.tsx';
import { ConcurrentAdvisory } from '@atlassian/jira-spa/src/view/concurrent-advisory-component/index.tsx';
import initReactUFO from '@atlassian/jira-ufo-interaction-metrics-init/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { visualRefreshResolverOverride } from '@atlassian/jira-visual-refresh-rollout/src/resolver-override/index.tsx';
import LooselyLazy, { MODE, type LazyComponent } from '@atlassian/react-loosely-lazy';
import {
	type Route,
	matchRoute,
	useRouterActions,
	type RouterContext,
} from '@atlassian/react-resource-router';
import { AtlaspackMetricsConnector } from '@atlassian/jira-atlaspack-global-metrics/src/index.tsx';
import {
	useRouterStoreActions,
	createRouterSelector,
} from '@atlassian/react-resource-router/src/controllers/router-store';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import { initEntryPointConfig } from '@atlassian/jira-entry-point-config/src/initEntryPointConfig.tsx';
import { getIsConcurrentEnabled } from '@atlassian/jira-react-concurrent/src/utils/get-is-concurrent-enabled.tsx';
import global from './global-core.tsx';

// Set SPA global
// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
window.__SPA__ = true;

const altBootstrapEnabled =
	// It is easier to make the check against window than it is to make typescript happy here.
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	!__SERVER__ && typeof window !== 'undefined' && window.__SPA_BOOTSTRAP_ALT;
// In the experiment scenario, I'm moving the side-effect from immediate execution to function execution.
if (!altBootstrapEnabled) {
	LooselyLazy.init({
		mode: (() => fg('jfp_magma_jira-rll-hydrate'))() ? MODE.HYDRATE : MODE.RENDER,
		crossOrigin: 'anonymous',
		// hide fg access from eslint, we can do it on "pure client
		react18: (() => fg('jfp_magma_jira-rll-react-18-render'))(),
		usePostTaskPhases: (() => fg('jfp-jira-remove-rll-subscriptions'))(),
	});
}

const getMessages = () => {
	setMark('jira-spa/language-pack.start');
	const messages = require('language-pack');

	setMark('jira-spa/language-pack.end');
	return messages;
};

const superbatchInit = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.DeferScripts = { deferState: 'done' };

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.body && document.body.classList.remove('deferred');
};

const globalSettingsArgs = { navigation: false, upflow: false } as const;

const InstallGlobalComponentsOnce = () => {
	useEffect(() => {
		global.installLowPriorityApps(
			// @ts-expect-error - TS2345 - Argument of type 'Partial<{ readonly navigation: false; readonly upflow: false; }>' is not assignable to parameter of type 'LowPriorityAppsArgs'.
			omit(globalSettingsArgs, ['flags', 'navigation', 'renderNavigation']),
		);
		setMark('JIRA_SPA_ENTRY_GLOBAL_INSTALL');
	}, []);
	return null;
};

const updateCapability = (capability: string | null) => {
	setCapability(capability);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (capability === null && window.AJS?.$?.ajaxSettings?.headers?.[CAPABILITY_HEADER_NAME]) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		delete window.AJS.$.ajaxSettings.headers[CAPABILITY_HEADER_NAME];
	} else {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.AJS?.$?.ajaxSetup({ headers: { [CAPABILITY_HEADER_NAME]: capability } });
	}
};

// @ts-expect-error - TS7031 - Binding element 'routes' implicitly has an 'any' type.
const CapabilityTrackerConnector = ({ routes }) => {
	const { registerBlock } = useRouterActions();
	registerBlock((nextLocation: Location) => {
		const nextMatchObject = matchRoute(
			routes,
			nextLocation.pathname,
			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Query | undefined'.
			nextLocation.search,
			'',
		);
		updateCapability(nextMatchObject?.route.meta?.capability || null);
		return true;
	});
	const route = useCurrentRoute();
	updateCapability(route.meta?.capability || null);
	return null;
};

const main = async (
	getInitialRoutes: () => Route[],
	loadLazyRoutes?: () => Promise<() => Route[]>,
) => {
	if (
		fg('add_ssr_placeholder_replacements_to_nin_and_nav') ||
		fg('add_ssr_placeholder_replacements_to_nav_for_iv') ||
		fg('add_nav_ssr_placeholder_for_board_and_backlog')
	) {
		collectSSRPlaceholderDimensions(document, window);
	}
	initReactUFO();
	initBrowserMetrics3();
	initPlatformFeatureFlags(visualRefreshResolverOverride);
	initEntryPointConfig();
	addInitialPageLoadTimings();
	stopInitialPageLoadTimingFromPerformanceMarkStart('jira-spa/init', 'jira-spa.js:eval-start');
	setMark('jira-spa/setup.start');

	const routes = measureInitialPageLoadTiming('jira-spa/setup/routes', () => getInitialRoutes());
	const history = measureInitialPageLoadTiming('jira-spa/setup/history', () =>
		createSpaHistory(routes),
	);

	const initRoute = measureInitialPageLoadTiming('jira-spa/setup/match-route', () => {
		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'Query | undefined'.

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const matchedRoute = matchRoute(routes, window.location.pathname, window.location.search);
		setMatchedRoute(matchedRoute?.route, matchedRoute?.match);
		return matchedRoute;
	});

	if (initRoute != null) {
		const ufoName = getUFORouteName(initRoute.route);
		traceUFOPageLoad(ufoName, initRoute.route.name);
	}

	const BrowserMetricsConnector = () => {
		const route = useCurrentRoute();
		browserMetrics.setRoute(route.name);

		useUFOTransitionCompleter();

		GlobalPageLoadExperience.setPageLoadId(route.perfMetricKey || 'UNKNOWN');
		return null;
	};

	GlobalPageLoadExperience.startPageLoad('UNKNOWN', true);

	const messages = getMessages();
	const tenantContext = getTenantContext_DEPRECATED_DO_NOT_USE();
	const { locale } = tenantContext;
	const routerProps = {
		history,
		routes,
		basePath: '',
		initialRoute: initRoute?.route,

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		resourceData: window.SPA_STATE,
		resourceContext: getSpaRouterContext({
			tenantContext,
		}),
		onPrefetch: ({ route, match }: RouterContext) => {
			setPrefetchStartMark(match);

			if (!route.forPaint) {
				return;
			}

			route.forPaint.map(
				(component: LazyComponent<any>) => component.preload && component.preload(),
			);
		},
		isLazyRoutes: typeof loadLazyRoutes === 'function',
	};

	let SpaClientRouter;
	if (typeof loadLazyRoutes === 'function') {
		const useRouterRoutes = createRouterSelector((state) => state.routes);
		SpaClientRouter = ({ children }: { children: ReactNode }) => {
			const actions = useRouterStoreActions();
			const currentRoutes = useRouterRoutes();
			useEffect(() => {
				let isCancelled = false;
				const loadRoutes = async () => {
					if (!isCancelled) {
						const getLazyRoutes = await loadLazyRoutes();
						await actions.setRoutes(getLazyRoutes());
					}
				};
				loadRoutes();
				return () => {
					isCancelled = true;
				};
			}, [actions]);

			return (
				<Router {...routerProps}>
					<JSErrorBoundary
						id="spa-main.client-router"
						packageName="jiraEntry"
						fallback="unmount"
						teamName="delorean-spa"
					>
						<AtlaspackMetricsConnector />
						<BrowserMetricsConnector />
						<CapabilityTrackerConnector routes={currentRoutes} />
						{/* @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'RouteContext': location, query, route, match, action */}
						<SessionTracker />
					</JSErrorBoundary>
					{children}
					<NonCritical id="spa-main.client-router.non-critical" teamName="delorean-spa">
						<InstallGlobalComponentsOnce />
					</NonCritical>
				</Router>
			);
		};
	} else {
		SpaClientRouter = ({ children }: { children: ReactNode }) => (
			<Router {...routerProps}>
				<JSErrorBoundary
					id="spa-main.client-router"
					packageName="jiraEntry"
					fallback="unmount"
					teamName="delorean-spa"
				>
					<AtlaspackMetricsConnector />
					<BrowserMetricsConnector />
					<CapabilityTrackerConnector routes={routes} />
					{/* @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'RouteContext': location, query, route, match, action */}
					<SessionTracker />
				</JSErrorBoundary>
				{children}
				<NonCritical id="spa-main.client-router.non-critical" teamName="delorean-spa">
					<InstallGlobalComponentsOnce />
				</NonCritical>
			</Router>
		);
	}
	const App = ({ placeholders }: { placeholders: Map<string, string> }) => (
		<StrictMode>
			<UFOSegment name="jira-spa">
				<EntryPointPlaceholderContext.Provider value={placeholders}>
					<Spa
						baseUrl=""
						locale={locale}
						messages={messages}
						Router={SpaClientRouter}
						initRoute={initRoute}
						routes={routes}
						routerLinkComponent={JiraRouterLinkConfiguration}
					/>
				</EntryPointPlaceholderContext.Provider>
			</UFOSegment>
		</StrictMode>
	);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.__NAV_VERSION__ = '3.0.0';

	stopInitialPageLoadTimingFromPerformanceMarkStart('jira-spa/setup', 'jira-spa/setup.start', true);

	setMark('_jira-spa/spa-shell.start');

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById('jira-frontend');

	if (container) {
		getVCObserver().setSSRElement(container);
		getVCObserver().setReactRootRenderStart();
		requestAnimationFrame(() => {
			requestAnimationFrame(() => {
				getVCObserver().setReactRootRenderStop();
			});
		});

		const placeholders = getPlaceholdersFromHTML();

		const isConcurrentEnabled = getIsConcurrentEnabled();

		// if the feature flag is false, do not opt into concurrent features
		// if the local env var is not set, or set to false, do not opt into concurrent features
		if (!isConcurrentEnabled) {
			render(<App placeholders={placeholders} />, container);
		}

		if (isConcurrentEnabled) {
			if (fg('jfp-magma-incremental-hydrate-root')) {
				const ConcurrentSpaApp = () => (
					<>
						<App placeholders={placeholders} />
						{fg('concurrent-rendering-change-advisory') ? (
							<ConcurrentAdvisory consoleMessage="This entire site is being rendered with React concurrent rendering go/r18" />
						) : null}
					</>
				);
				if (initRoute?.route?.isHydrateRootEnabled) {
					hydrateRoot(container, <ConcurrentSpaApp />);
				} else {
					const root = createRoot(container);
					root.render(<ConcurrentSpaApp />);
				}
			} else {
				const root = createRoot(container);
				root.render(
					<>
						<App placeholders={placeholders} />
						{fg('concurrent-rendering-change-advisory') ? (
							<ConcurrentAdvisory consoleMessage="This entire site is being rendered with React concurrent rendering go/r18" />
						) : null}
					</>,
				);
			}
		}
	}

	stopInitialPageLoadTimingFromPerformanceMarkStart(
		'jira-spa/spa-shell',
		'_jira-spa/spa-shell.start',
	);
	setMark('JIRA_SPA_ENTRY_REACT_DOM_RENDER');
	setTimeout(() => {
		setInitialPageLoadTimingFromPerformanceMarks(
			'jira-spa',
			'commons-entry.js:eval-stop',
			'jira-spa.js:eval-stop',
		);
	});

	if (expVal('uip_preprod_slow', 'value', 0) > 0) {
		setMark('slow-start');
		const end = performance.now() + expVal('uip_preprod_slow', 'value', 0);
		while (performance.now() < end) {
			// do nothing
		}
		setMark('slow-stop');
	}

	global.installHighPriorityApps(
		// @ts-expect-error - TS2345 - Argument of type 'Partial<{ readonly navigation: false; readonly upflow: false; }>' is not assignable to parameter of type 'HighPriorityAppsArgs'.
		pick(globalSettingsArgs, ['flags', 'navigation', 'renderNavigation']),
	);
	superbatchInit();

	return null;
};

export const startJiraSpa = async (
	getInitialRoutes: () => Route[],
	loadLazyRoutes?: () => Promise<() => Route[]>,
) => {
	if (altBootstrapEnabled) {
		LooselyLazy.init({
			mode: MODE.RENDER,
			crossOrigin: 'anonymous',
		});
	}

	return main(getInitialRoutes, loadLazyRoutes).catch((reason) => reportBootstrapError(reason));
};
