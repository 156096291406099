import React from 'react';

import { ProjectsIcon } from '@atlaskit/temp-nav-app-icons/projects';

import { GlobalShortcutsItem } from '../../types';

export default function projects(
	localizedName: string,
	fallbackUrl: () => string,
): GlobalShortcutsItem {
	return {
		icon: <ProjectsIcon size="20" />,
		label: localizedName,
		fallbackUrl,
	};
}
