import React from 'react';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-calendar/entrypoint.tsx';
import { horizontalNavQueueEntrypoint } from '@atlassian/jira-horizontal-nav-queue/entrypoint.tsx';
import { QueueNav3StylingPatchContainer } from '@atlassian/jira-horizontal-nav-queue/src/controllers/styling-patch/index.tsx';
import { ClassicCalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-calendar/ClassicCalendarSkeleton.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

export const calendarPageNav3EntryPoint = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const calendarPageEntryPoint = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: horizontalNavQueueEntrypoint,
});

export const CalendarSkeleton = () =>
	getWillShowNav4() && !fg('jsm_fix_queue_issue_view_navigate_back_state') ? (
		<ClassicCalendarSkeleton />
	) : (
		<QueueNav3StylingPatchContainer>
			<ClassicCalendarSkeleton />
		</QueueNav3StylingPatchContainer>
	);
