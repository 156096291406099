import React from 'react';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { horizontalNavQueueEntrypoint } from '@atlassian/jira-horizontal-nav-queue/entrypoint.tsx';
import { boardEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-board/entrypoint.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';
import { QueueNav3StylingPatchContainer } from '@atlassian/jira-horizontal-nav-queue/src/controllers/styling-patch/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

export const boardPageNav3EntryPoint = createPageEntryPoint({
	main: boardEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const boardPageEntryPoint = createPageEntryPoint({
	main: boardEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: horizontalNavQueueEntrypoint,
});

export const BoardSkeleton = () =>
	getWillShowNav4() && !fg('jsm_fix_queue_issue_view_navigate_back_state') ? (
		<ClassicBoardSkeleton />
	) : (
		<QueueNav3StylingPatchContainer>
			<ClassicBoardSkeleton />
		</QueueNav3StylingPatchContainer>
	);
