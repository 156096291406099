import React from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { Stack, Text } from '@atlaskit/primitives';

const HealthcheckSuggestedAction = (props: {
	error: string;
	action: string | null;
	link: string | null;
}) => {
	const actions = props.link
		? [
				<SectionMessageAction key="action" href={props.link}>
					Go to page
				</SectionMessageAction>,
			]
		: [];

	return (
		<>
			<SectionMessage title="Failure" appearance="error" actions={actions}>
				<Stack space="space.100">
					<Text>{props.error}</Text>
					<Text>{props.action}</Text>
				</Stack>
			</SectionMessage>
		</>
	);
};

export default HealthcheckSuggestedAction;
