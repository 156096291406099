import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerLabel: {
		id: 'jet-plan-action-menu.more-nav-menu-button.trigger-label',
		defaultMessage: 'Plan settings',
		description: 'Label for dropdown menu trigger in the plans navigation.',
	},
	moreActionsTooltip: {
		id: 'jet-plan-action-menu.more-nav-menu-button.tooltip',
		defaultMessage: 'More actions',
		description: 'Tooltip for dropdown menu trigger in the plans navigation.',
	},
});
