import isNil from 'lodash/isNil';
import {
	Observable,
	type RequestParameters,
	type Variables,
	type GraphQLSingularResponse,
	type GraphQLResponse,
	type CacheConfig,
} from 'relay-runtime';
import {
	performPostRequest,
	performPostRequestWithRetry,
	applyErrorHandling,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { retryOnError } from '@atlassian/jira-fetch/src/utils/retries.tsx';
import { captureErrors, isUnauthenticated } from '@atlassian/jira-relay-errors/src/index.tsx';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises/src/index.tsx';
import {
	QueryResponseCache,
	getQueryResponseCache as queryResponseCacheGetter,
} from '@atlassian/jira-relay-query-responses/src/index.tsx';
import {
	claimEarlyScriptPromise,
	getEarlyScriptPromiseKey,
} from '@atlassian/jira-relay-vendorless-utils/src/services/early-script-promise/index.tsx';
import { putExtensions } from '@atlassian/relay-capture-extensions';
import { getAggEndpoint, getRequestBody } from '@atlassian/relay-endpoint';
import { addTraceId } from '@atlassian/relay-traceid';
import { showUnauthenticatedFlag } from './services/error-flags/index.tsx';
import { getLoggingAndMetricsHelper } from './services/logging-and-metrics/index.tsx';
import {
	checkAuthentication,
	getTraceIdFromExtensions,
	blocklist,
	generateOptions,
	shouldRetry,
	getRetryAttemptsForQuery,
} from './utils.tsx';

const getQueryResponseCache = () =>
	fg('jfp_magma_fix_relay_query_cache_loading_from_ssr')
		? queryResponseCacheGetter()
		: QueryResponseCache;

export const fetch = (
	request: RequestParameters,
	variables: Variables,
	cacheConfig: CacheConfig,
): Observable<GraphQLResponse> => {
	const redirecting = checkAuthentication();
	if (redirecting)
		// Hang the request if we are redirecting - should be cleared on redirect
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return Observable.from(new Promise<GraphQLResponse>(() => {}));

	const { name: queryName, operationKind } = request;

	const earlyScriptPromise =
		(!__SERVER__ && claimEarlyScriptPromise(getEarlyScriptPromiseKey(request, variables))) ||
		undefined;

	const loggingMetadata = {
		queryName,
		operationKind,
	};
	const loggingAndMetrics = getLoggingAndMetricsHelper(loggingMetadata);

	return Observable.create((sink) => {
		try {
			const requestID =
				'cacheID' in request && request.cacheID != null ? request.cacheID : request.id;
			const endpoint = getAggEndpoint(request, cacheConfig);
			const body = getRequestBody(request, variables);

			let ssrCache = null;
			if (!__SERVER__ && requestID != null) {
				ssrCache = getQueryResponseCache().get(requestID, variables);
			}

			// On client read cache from window.SPA_STATE if available
			if (!__SERVER__ && !isNil(ssrCache)) {
				sink.next(ssrCache);
				if (requestID != null) {
					getQueryResponseCache().delete(requestID, variables);
				}
				sink.complete();
				return;
			}

			const wrappedEarlyScriptPromise =
				earlyScriptPromise &&
				retryOnError<GraphQLSingularResponse>(() => applyErrorHandling(earlyScriptPromise), {
					retryFunc: () =>
						performPostRequest(
							endpoint,
							generateOptions(
								{
									body,
								},
								endpoint,
							),
						),
					retryPredicate: shouldRetry,
					retryAttempts: getRetryAttemptsForQuery(queryName, operationKind),
					onRetry: loggingAndMetrics.logErrorRetry,
				});

			// Throws HttpError
			const promise: Promise<GraphQLSingularResponse> =
				!__SERVER__ && wrappedEarlyScriptPromise !== undefined
					? wrappedEarlyScriptPromise
					: Promise.resolve(
							performPostRequestWithRetry(endpoint, {
								...generateOptions(
									{
										body,
									},
									endpoint,
								),
								retryPredicate: shouldRetry,
								retryAttempts: getRetryAttemptsForQuery(queryName, operationKind),
								onRetry: (error, attempt) => {
									loggingAndMetrics.logErrorRetry(error, attempt);
								},
							}),
						);

			promise
				.then((response: GraphQLSingularResponse) => {
					addTraceId(queryName, getTraceIdFromExtensions(response));
					putExtensions(queryName, response.extensions);

					// @ts-expect-error - TS2339 - Property 'errors' does not exist on type 'GraphQLSingularResponse'.
					const errors = response.errors || undefined;
					if (errors != null) {
						const requestId = response?.extensions?.gateway?.request_id;
						if (!blocklist.includes(queryName)) {
							loggingAndMetrics.logNonBreakingErrors(errors, requestId);
						}
						captureErrors(errors, { operationName: queryName, requestId });

						if (isUnauthenticated(errors)) {
							//  Short circuit operation and handle the unauthorized errors.
							showUnauthenticatedFlag(queryName);
							// Surprise behaviour: the observable should hang indefinitely here!
							// At least, that is the expectation of existing code; see src/packages/issue/issue-view/src/integration-tests/experience-tracking/view.cy-integration.tsx
							// Which assumes that the operation never completes, so relay never receives a (null) response, so it never complains about the data.
							// This should be revised; see https://jplat.atlassian.net/browse/MAGMA-3087
							return;
						}
					}

					if (
						response.data &&
						(response.data.jira === null || response.data.customerService === null)
					) {
						// Enforce non-nullability of the jira and customerService namespaces. This is an intermediary solution to
						// prevent server errors/gateway timeouts from wiping the Relay store. Eventually we'd like this
						// to be enforced at a schema level, see further discussion https://hello.atlassian.net/wiki/spaces/~236031707/pages/2034382845/Top+level+errors+for+namespaces+in+AGG+Relay
						// @ts-expect-error - TS2322 - Relay types do not allow null data for a response with errors, however this is valid according to the spec http://spec.graphql.org/June2018/#sec-Data
						response.data = null;
					}

					// cache query response on SSR
					if (__SERVER__ && requestID != null) {
						getQueryResponseCache().setWithMetadata(
							requestID,
							variables,
							response,
							loggingMetadata,
						);
					}
					sink.next(response);
					sink.complete();
				})
				// We don't await the promise so the catch block below is never
				// triggered so we need an independent catch on the promise
				.catch((error) => {
					loggingAndMetrics.logError(error);
					sink.error(error);
				});

			if (requestID != null) {
				QueryPromisesMap.set(requestID, promise);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			loggingAndMetrics.logError(error);
			sink.error(error);
		}
	});
};
