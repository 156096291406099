import { fg } from '@atlassian/jira-feature-gating';
import {
	getIsAdmin,
	getIsNav4TenantOptIn,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';
import { getIsInPreGaStage } from '../../get-is-in-pre-ga-stage.tsx';

export const getWillShowNav4UserOptOutForGa = () => {
	// Previous stages were mutually exclusive. GA is not.
	// Therefore, we need to make sure the customer is not in another stage before showing user opt out.
	if (getIsInPreGaStage()) {
		return false;
	}

	// Later GA phases eclipse earlier phases.
	if (fg('jira_nav4_ga_phase-3')) {
		return false;
	}

	if (fg('jira_nav4_ga_phase-2')) {
		return true;
	}

	if (fg('jira_nav4_ga_phase-1')) {
		return !getIsAdmin() && getIsNav4TenantOptIn();
	}

	return false;
};
