/**
 * @generated SignedSource<<bb6ccc7eac08a1105992bebc60cef8e3>>
 * @relayHash 468bd2fd6be3e0a524cf768954902ed2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 821883c1b29c6cacbbff1f0c106e332dc2690e95a2824e7c748095e1acabdf4a

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

import withMergedWorkflows_provider from '@atlassian/jira-relay-provider/src/merge-board-workflows.relayprovider';
import withCategoryField_provider from '@atlassian/jira-relay-provider/src/relay-category-field.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "821883c1b29c6cacbbff1f0c106e332dc2690e95a2824e7c748095e1acabdf4a",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider": withCategoryField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider": withMergedWorkflows_provider
    }
  }
};

export default node;
