/* eslint-disable @typescript-eslint/no-explicit-any */
import type { EntryPoint } from 'react-relay';

/**
 * Recursively run through all nested entrypoints with a given callback
 */
function forAllEntryPoints<TEntryPointParams extends {}>(
	entryPoint: EntryPoint<any, TEntryPointParams>,
	entryPointParams: TEntryPointParams,
	callback: (currentEntryPoint: EntryPoint<any, TEntryPointParams>) => void,
) {
	callback(entryPoint);
	const preloadProps = entryPoint.getPreloadProps(entryPointParams);
	const entryPoints = preloadProps.entryPoints;
	if (!entryPoints) {
		return;
	}
	Object.values(entryPoints).forEach(
		({ entryPoint: nestedEntryPoint, entryPointParams: nestedParams }) => {
			if (nestedEntryPoint) {
				forAllEntryPoints(nestedEntryPoint, nestedParams, callback);
			}
		},
	);
}

export default forAllEntryPoints;
