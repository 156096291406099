import type {
	JSResourceOptions,
	Loader,
	PreloadableJSResourceReference,
	SSRImportLiftableJSResourceOptions,
} from '../../types';
import { createCache } from '../../utils';
import { PreloadableJSResource } from '../preloadable-js-resource';
import { postTask } from '../scheduler';

const cache = createCache(new Map<string, PreloadableJSResourceReference<any>>());

export function JSResourceForUserVisible<T>(
	loader: Loader<T>,
	opts?: JSResourceOptions & SSRImportLiftableJSResourceOptions,
): PreloadableJSResourceReference<T> {
	return cache.get(opts!.moduleId!, () =>
		PreloadableJSResource(() => postTask(loader, { priority: 'user-blocking' }), opts!),
	);
}
