import React from 'react';
import { Inline, Stack, Text, xcss } from '@atlaskit/primitives';

import Spinner from '@atlaskit/spinner';
import SectionMessage from '@atlaskit/section-message';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { Healthcheck, HealthcheckEntry } from '../common/types.tsx';
import HealthcheckTable from './healthcheck-table/index.tsx';
import HealthcheckHeader from './healthcheck-header/index.tsx';
import HealthcheckSuggestedAction from './healthcheck-suggested-action/index.tsx';

const HealthcheckPageContents = (props: { healthcheck: Healthcheck | null }) => {
	const failedPrecondition = props.healthcheck?.healthcheckPreconditions.find(
		(item) => item.status.statusType === 'FAILURE',
	);
	const failedEntry = props.healthcheck?.healthcheckEntries.find(
		(item) => item.status.statusType === 'FAILURE',
	);
	const failedStatus = props.healthcheck?.healthcheckStatuses.find(
		(item) => item.status.statusType === 'FAILURE',
	);

	const displayFailedStatusForEntry = (healthcheck: Healthcheck, failedItem: HealthcheckEntry) => {
		let errorString = `The healthcheck failed at step "${failedItem.name}"`;
		if ('message' in failedItem.status) {
			errorString = `${errorString} due to reason "${failedItem.status.message}".`;
		} else {
			errorString = `${errorString}.`;
		}
		return (
			<HealthcheckSuggestedAction
				error={errorString}
				action={healthcheck.suggestedAction}
				link={healthcheck.suggestedActionLink}
			/>
		);
	};

	const displayStatus = () => {
		if (props.healthcheck === null) {
			return <></>;
		}

		if (failedPrecondition) {
			return displayFailedStatusForEntry(props.healthcheck, failedPrecondition);
		}
		if (failedEntry) {
			return displayFailedStatusForEntry(props.healthcheck, failedEntry);
		}
		if (failedStatus) {
			return displayFailedStatusForEntry(props.healthcheck, failedStatus);
		}

		return (
			<SectionMessage title="Success" appearance="success">
				<Text>Extra Authenticated Applinks are ready for use.</Text>
			</SectionMessage>
		);
	};

	return (
		<>
			<JSErrorBoundary
				id="applinks-extra-auth-healthcheck-page"
				teamName="olympus"
				packageName="applinksExtraAuthHealthcheckPage"
			>
				<Stack space="space.200" xcss={styles}>
					<HealthcheckHeader />
					{props.healthcheck ? (
						<Stack space="space.800">
							{displayStatus()}
							<HealthcheckTable
								title="Prerequisites"
								description="Ensure that the following prerequisites are met before beginning the setup process."
								healthcheckEntries={props.healthcheck.healthcheckPreconditions}
							/>
							<HealthcheckTable
								title="OAuth 2.0 Provider Configuration"
								description="Extra Authentication uses an external OAuth 2.0 provider, shared by all products within your organization. The provider must be configured correctly before Application Links can be created."
								healthcheckEntries={props.healthcheck.healthcheckEntries}
							/>
							<HealthcheckTable
								title="Applink Status"
								description="Once the authentication provider is configured, you can now create Application Links. All links configured to use Extra Authentication for this site are displayed below."
								healthcheckEntries={props.healthcheck.healthcheckStatuses}
							/>
						</Stack>
					) : (
						<Inline space="space.200">
							<Spinner
								testId="applinks-extra-auth.ui.spinner"
								interactionName="load"
								label="Loading"
							/>
							<Text>Loading healthcheck data</Text>
						</Inline>
					)}
				</Stack>
			</JSErrorBoundary>
		</>
	);
};

export default HealthcheckPageContents;
const styles = xcss({ padding: 'space.200' });
