import React from 'react';
import Heading from '@atlaskit/heading';
import { Stack, Text } from '@atlaskit/primitives';

const HealthcheckHeader = () => {
	return (
		<>
			<Stack space="space.200">
				<Heading size="large">Extra Auth Healthcheck</Heading>
				<Text>Status of Extra Authenticated Application Links for your Jira Cloud site.</Text>
			</Stack>
		</>
	);
};

export default HealthcheckHeader;
