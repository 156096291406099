import React from 'react';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import RouteWrapperHealthcheckPage from './ui/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HealthcheckPage = (props: Record<any, any>) => (
	<UFOSegment name="applinks-extra-auth-healthcheck-page">
		<RouteWrapperHealthcheckPage {...props} />
		<SpaStatePageReady />
	</UFOSegment>
);

export default HealthcheckPage;
