import React, { useMemo, useEffect } from 'react';
import { type PreloadedQuery, graphql, usePreloadedQuery, useFragment } from 'react-relay';
import { useOverviewTheme } from '@atlassian/jira-business-entity-overview/src/services/overview-theme/index.tsx';
import { useProjectTheme } from '@atlassian/jira-business-entity-project/src/services/project-theme/index.tsx';
import type {
	ThemeSetting,
	ColorThemeName,
	GradientThemeName,
	ImageThemeSetting,
} from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { ThemeTokensSetter } from '@atlassian/jira-project-theme-components/src/ui/theme-tokens-setter/index.tsx';
import {
	ThemeContainer,
	ThemeContainerOld,
} from '@atlassian/jira-project-theme-providers/src/controllers/theme/index.tsx';
import type { ThemeSetterQuery } from '@atlassian/jira-relay/src/__generated__/ThemeSetterQuery.graphql';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import type { ThemeSetterBackground$key } from '@atlassian/jira-relay/src/__generated__/ThemeSetterBackground.graphql';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';

export const ThemeSetter = ({
	queryReference,
}: {
	queryReference: PreloadedQuery<ThemeSetterQuery>;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const data = usePreloadedQuery<ThemeSetterQuery>(
		graphql`
			query ThemeSetterQuery($cloudId: ID!, $projectIdOrKey: String!) @preloadable {
				jira @required(action: THROW) {
					mediaClientId(cloudId: $cloudId)
					mediaExternalEndpointUrl(cloudId: $cloudId)
				}
				jira_projectByIdOrKey(cloudId: $cloudId, idOrKey: $projectIdOrKey) {
					projectId
					projectType
					background {
						...ThemeSetterBackground
					}
				}
			}
		`,
		queryReference,
	);

	const backgroundData = useFragment<ThemeSetterBackground$key>(
		graphql`
			fragment ThemeSetterBackground on JiraActiveBackgroundDetailsResult {
				__typename
				... on JiraColorBackground {
					colorValue
				}
				... on JiraGradientBackground {
					gradientValue
				}
				... on JiraMediaBackground {
					customBackground {
						id
						mediaApiFileId
						mediaReadToken(durationInSeconds: 900)
						sourceType
						sourceIdentifier
						altText
					}
				}
			}
		`,
		data.jira_projectByIdOrKey?.background ?? null,
	);

	const { hasSoftwareTheming, isSoftwareProject } = getIsSoftwareThemingEnabled()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSoftwareProjectTheming()
		: { hasSoftwareTheming: false, isSoftwareProject: false };

	const media = useMemo(() => {
		return data.jira.mediaClientId && data.jira.mediaExternalEndpointUrl
			? {
					clientId: data.jira.mediaClientId,
					externalEndpointUrl: data.jira.mediaExternalEndpointUrl,
				}
			: null;
	}, [data.jira.mediaClientId, data.jira.mediaExternalEndpointUrl]);

	const projectTheme = useMemo(() => {
		let themeSetting: ThemeSetting | null = null;

		if (!backgroundData) return null;

		switch (backgroundData.__typename) {
			case 'JiraColorBackground':
				themeSetting = {
					type: 'color',
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					value: backgroundData.colorValue as ColorThemeName,
				};
				break;
			case 'JiraGradientBackground':
				themeSetting = {
					type: 'color',
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					value: backgroundData.gradientValue as GradientThemeName,
				};
				break;
			case 'JiraMediaBackground':
				if (backgroundData.customBackground != null) {
					const { id, mediaApiFileId, mediaReadToken, sourceType, sourceIdentifier, altText } =
						backgroundData.customBackground;
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					themeSetting = {
						type: 'image' as const,
						value: 'image' as const,
						id,
						mediaApiFileId,
						mediaReadToken,
						sourceType,
						sourceIdentifier,
						altText,
					} as ImageThemeSetting;
				}
				break;
			default:
				themeSetting = null;
		}

		return themeSetting
			? {
					themeSetting,
					entityId: `project/${data.jira_projectByIdOrKey?.projectId}`,
				}
			: null;
	}, [backgroundData, data.jira_projectByIdOrKey?.projectId]);

	const overviewTheme = useOverviewTheme();
	const route = useCurrentRoute();

	const entityTheme = useMemo(() => {
		let result = projectTheme ?? overviewTheme;

		if (result != null) {
			const isFormSubmitView =
				route.name === 'classic-business-form-submit' ||
				route.name === 'classic-business-form-submit-embed';

			// the form submit view doesn't use the project theme colors
			// since it is accessed externally to the project
			// remove themeSetting for pages in software projects that don't support theming yet
			if (isFormSubmitView || (isSoftwareProject && !hasSoftwareTheming)) {
				result = { ...result, themeSetting: null };
			}
		}

		return result;
	}, [projectTheme, overviewTheme, route.name, isSoftwareProject, hasSoftwareTheming]);

	useEffect(() => {
		const projectId = data.jira_projectByIdOrKey?.projectId;
		if (projectId && fg('jira_theming_relay_migration')) {
			if (
				data.jira_projectByIdOrKey?.projectType === 'BUSINESS' ||
				(data.jira_projectByIdOrKey?.projectType === 'SOFTWARE' && getIsSoftwareThemingEnabled())
			) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'theme evaluated', {
					theme: entityTheme?.themeSetting?.value,
					projectId,
				});
			}
		}
	}, [
		entityTheme?.themeSetting?.value,
		data.jira_projectByIdOrKey?.projectId,
		data.jira_projectByIdOrKey?.projectType,
		createAnalyticsEvent,
	]);

	if (getWillShowNav4()) {
		return (
			<>
				{!fg('jira_theming_relay_migration') && (
					<ThemeContainer isGlobal entityTheme={entityTheme} />
				)}
				<ThemeTokensSetter
					{...(fg('jira_theming_relay_migration')
						? {
								media,
								themeSetting: entityTheme?.themeSetting,
								projectType: data.jira_projectByIdOrKey?.projectType,
							}
						: {})}
				/>
			</>
		);
	}
	return (
		<>
			{entityTheme != null && <ThemeContainerOld isGlobal {...entityTheme} />}
			<ThemeTokensSetter
				{...(fg('jira_theming_relay_migration')
					? {
							media,
							themeSetting: entityTheme?.themeSetting,
							projectType: data.jira_projectByIdOrKey?.projectType,
						}
					: {})}
			/>
		</>
	);
};

export const ThemeSetterOld = () => {
	const projectTheme = useProjectTheme();
	const overviewTheme = useOverviewTheme();
	const route = useCurrentRoute();

	const entityTheme = useMemo(() => {
		let result = projectTheme ?? overviewTheme;

		if (result != null) {
			const isFormSubmitView =
				route.name === 'classic-business-form-submit' ||
				route.name === 'classic-business-form-submit-embed';

			// the form submit view doesn't use the project theme colors
			// since it is accessed externally to the project
			if (isFormSubmitView) {
				result = { ...result, themeSetting: null };
			}
		}

		return result;
	}, [projectTheme, overviewTheme, route.name]);
	if (getWillShowNav4()) {
		return (
			<>
				<ThemeContainer isGlobal entityTheme={entityTheme} />
				<ThemeTokensSetter />
			</>
		);
	}
	return (
		<>
			{entityTheme != null && <ThemeContainerOld isGlobal {...entityTheme} />}
			<ThemeTokensSetter />
		</>
	);
};
