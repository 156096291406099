import React from 'react';

import { ConfluenceIcon } from '@atlaskit/temp-nav-app-icons/confluence';

import { Products } from '../../constants';
import { GlobalShortcutsItem } from '../../types';

export default function confluence(fallbackUrl: () => string): GlobalShortcutsItem {
	return {
		icon: <ConfluenceIcon size="20" />,
		label: 'Confluence',
		apsKey: Products.CONFLUENCE,
		fallbackUrl,
	};
}
