import React from 'react';

import { Stack, Text } from '@atlaskit/primitives';
import TableTree from '@atlaskit/table-tree';

import SuccessIcon from '@atlaskit/icon/core/success';
import CrossCircleIcon from '@atlaskit/icon/core/cross-circle';
import TaskToDoIcon from '@atlaskit/icon/core/task-to-do';

import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';
import EmptyState from '@atlaskit/empty-state';
import type { HealthcheckEntry, HealthcheckStatus } from '../../common/types.tsx';

const HealthcheckIcon = (props: HealthcheckEntry) => {
	return (
		<>
			{props.status.statusType === 'SUCCESS' && (
				<SuccessIcon color={token('color.icon.success')} label="Success" />
			)}
			{props.status.statusType === 'FAILURE' && (
				<CrossCircleIcon color={token('color.icon.danger')} label="Unsuccessful" />
			)}
			{props.status.statusType === 'NOT_TRIED' && (
				<TaskToDoIcon color={token('color.icon')} label="Not Tried" />
			)}
		</>
	);
};

const getColorFromStatus = (status: HealthcheckStatus) => {
	if (status.statusType === 'NOT_TRIED') {
		return 'color.text.disabled';
	}
	return 'color.text';
};

const HealthcheckTitle = (props: HealthcheckEntry) => {
	return <Text color={getColorFromStatus(props.status)}>{props.name}</Text>;
};

const HealthcheckComment = (props: HealthcheckEntry) => {
	const message = 'message' in props.status ? props.status.message : '';
	return <Text color={getColorFromStatus(props.status)}>{message}</Text>;
};

type Item = {
	id: string;
	content: HealthcheckEntry;
	hasChildren: boolean;
	children?: Item[];
};

const convertToArray = (healthcheckEntries: HealthcheckEntry[]): Item[] => {
	const wrapWithItem = (
		id: string,
		content: HealthcheckEntry,
		hasChildren: boolean,
		children?: Item[],
	) => {
		return { id, content, hasChildren, children };
	};

	return healthcheckEntries.map((entry, index) => {
		return wrapWithItem(`entry-${index}`, entry, false);
	});
};

const HealthcheckTable = (props: {
	title: string;
	description: string;
	healthcheckEntries: HealthcheckEntry[];
}) => {
	const healthcheckItems = convertToArray(props.healthcheckEntries);

	return (
		<Stack space="space.100">
			<Heading size="medium">{props.title}</Heading>
			<Text>{props.description}</Text>
			<TableTree
				columns={[HealthcheckIcon, HealthcheckTitle, HealthcheckComment]}
				headers={['Status', 'Item', 'Details']}
				columnWidths={['100px', '50%', '50%']}
				items={healthcheckItems}
				label="Healthcheck Display"
			>
				{healthcheckItems.length === 0 && (
					<EmptyState header="Not tried" description="Address the above issues to continue." />
				)}
			</TableTree>
		</Stack>
	);
};

export default HealthcheckTable;
