import React, { useState, useEffect } from 'react';

import { withRouter } from '@atlassian/react-resource-router';
import { getHealthcheck } from '../rest/index.tsx';
import type { Healthcheck } from '../common/types.tsx';
import HealthcheckPageContents from './main.tsx';

export const HealthcheckPage = () => {
	const [healthcheck, setHealthcheck] = useState<Healthcheck | null>(null);

	useEffect(() => {
		getHealthcheck().then((data) => {
			setHealthcheck(data);
		});
	}, []);

	return <HealthcheckPageContents healthcheck={healthcheck} />;
};

export default withRouter(HealthcheckPage);
